import logo from './logo.svg';
import './App.css';
import React, {Component} from 'react';
import Picker from 'react-scrollable-picker';

class App extends Component {
    constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        kg: '90',
        dg: '0',
        cm: '90,0'
      }, 
      optionGroups: {
        kg: [
          { value: '80', label: '80' },
          { value: '81', label: '81' },
		  { value: '82', label: '82' },
		  { value: '83', label: '83' },
		  { value: '84', label: '84' },
		  { value: '85', label: '85' },
		  { value: '86', label: '86' },
		  { value: '87', label: '87' },
		  { value: '88', label: '88' },
		  { value: '89', label: '89' },
		  { value: '90', label: '90' },
		  { value: '91', label: '91' },
		  { value: '92', label: '92' },
		  { value: '93', label: '93' },
		  { value: '94', label: '94' },
		  { value: '95', label: '95' },
		  { value: '96', label: '96' },
		  { value: '97', label: '97' },
		  { value: '98', label: '98' },
		  { value: '99', label: '99' },
        ],
        dg: [
          { value: '0', label: '0' },
          { value: '1', label: '1' },
		  { value: '2', label: '2' },
		  { value: '3', label: '3' },
		  { value: '4', label: '4' },
		  { value: '5', label: '5' },
		  { value: '6', label: '6' },
		  { value: '7', label: '7' },
		  { value: '8', label: '8' },
		  { value: '9', label: '9' },
        ],
        cm: [
          { value: '85,0', label: '85,0' },
		  { value: '85,5', label: '85,5' },
		  { value: '86,0', label: '86,0' },
		  { value: '86,5', label: '86,5' },
		  { value: '87,0', label: '87,0' },
		  { value: '87,5', label: '87,5' },
		  { value: '88,0', label: '88,0' },
		  { value: '88,5', label: '88,5' },
		  { value: '89,0', label: '89,0' },
		  { value: '89,5', label: '89,5' },
		  { value: '90,0', label: '90,0' },
		  { value: '90,5', label: '90,5' },
		  { value: '91,0', label: '91,0' },
		  { value: '91,5', label: '91,5' },
		  { value: '92,0', label: '92,0' },
		  { value: '92,5', label: '92,5' },
		  { value: '93,0', label: '93,0' },
		  { value: '93,5', label: '93,5' },
		  { value: '94,0', label: '94,0' },
		  { value: '94,5', label: '94,5' },
		  { value: '95,0', label: '95,0' },
		  { value: '95,5', label: '95,5' },
		  { value: '96,0', label: '96,0' },
		  { value: '96,5', label: '96,5' },
		  { value: '97,0', label: '97,0' },
		  { value: '97,5', label: '97,5' },
		  { value: '98,0', label: '98,0' },
		  { value: '98,5', label: '98,5' },
		  { value: '99,0', label: '99,0' },
		  { value: '99,5', label: '99,5' },
        ],
      },
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({valueGroups}) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      }
    }));
  };

  render() {
    const {optionGroups, valueGroups} = this.state;

    return (
      <Picker
        optionGroups={optionGroups}
        valueGroups={valueGroups}
        onChange={this.handleChange} />
    );
  }
}

export default App;
